import { getDashboardListAPI,getRiskProfileListAPI,getOrderBookListAPI,getInvestmentListAPI,getPortFolioDataDetailsAPI,getSubDomainExistAPI } from "../../apis/customers/DashboardAPI";

export default class BrokerService  {

    async getDashboardList(requestToken,clientID) {
        return await getDashboardListAPI(requestToken,clientID);    
    }

    async getRiskProfileList(requestToken,clientID) {
        return await getRiskProfileListAPI(requestToken,clientID);    
    }

    async getOrderBookList(requestToken,clientID) {
        return await getOrderBookListAPI(requestToken,clientID);    
    }

    async getInvestmentList(requestToken,clientID) {
        return await getInvestmentListAPI(requestToken,clientID);    
    }

    async getPortFolioDataDetails(token, id, portfolioID) {
        return await getPortFolioDataDetailsAPI(token, id, portfolioID);    
    } 

    async getSubDomainExist(subDomain) {
        return await getSubDomainExistAPI(subDomain);    
    }
}